import React from "react";
import { Link } from "gatsby";
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import favicon from '../images/favicon.ico';

// components
import Navbar from '../components/navbar/navbar';
import Footer from '../components/footer/footer';
import Article from '../components/article/article';
import ContactSection from "../components/contact/contact";

// images
import img_text_section from "../images/vector/vector-radgivning.svg";


function Radgivning() {
    return (
        <Layout>
            <Helmet>
                <title>Excendo Redovisning AB</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta property="og:title" content="Excendo Redovisning AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="" />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="sv_SE" />
                <meta property="og:url" content="http://www.excendo.se/" />
                <link rel="cannonical" href="http://www.excendo.se/" />
            </Helmet>

            <Navbar whitemode={true} />


            <section className="-mt-20">
                {/*-------------- hero section - START ---------------*/}
                <div className="w-screen bg-radgivning-hero bg-center bg-no-repeat bg-cover">
                    <div className="custome_overlay flex justify-center items-center py-48">
                        <h1 className="text-4xl font-semibold text-white text-center">Rådgivning</h1>
                    </div>
                </div>
                {/*-------------- hero section - END ---------------*/}
            </section>


            <section>
                {/*-------------- text section - START ---------------*/}
                <Article
                    image={img_text_section}
                    image_alt=""
                    heading="Rådgivning"
                    title="Expertrådgivning inom ekonomi"
                >
                    <p>
                        Behöver ditt företag expertrådgivning inom ekonomi? 
                        Våra auktoriserade konsulter erbjuder expertrådgivning 
                        i det mesta kring företagande och mer avancerade bolagsärenden. 
                         Det kan vara skattefrågor, för dig som ägare eller för företaget, 
                         försäljning eller köp av företag, delägaravtal eller omstrukturering.
                    </p>
                </Article>
                {/*-------------- text section - END ---------------*/}
            </section>



            <div className="container mx-auto px-4 xl:px-24">
                <hr className="border-1 border-lightgray-excendo"></hr>
            </div>



            <section className="my-32">
                {/*-------------- other services - START ---------------*/}
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-20">
                        <div className="px-8 md:px-0">
                            <div className="bg-red-100 rounded-full w-16 h-16 flex justify-center items-center text-orange-excendo shadow-2xl">
                                <svg className="h-8 w-8" viewBox="0 0 20 20" fill="currentColor" >
                                    <path d="M19.0579+0.917844C18.6668+0.526798+18.1246+0.347649+17.5804+0.423739C15.0058+0.784927+8.97835+1.63059+3.34479+10.484L9.49075+16.629C18.3403+10.9964+19.1889+4.96796+19.551+2.39341C19.6271+1.84634+19.448+1.30793+19.0579+0.917844ZM10.4173+11.0012C9.61982+11.0012+8.97257+10.354+8.97257+9.55648C8.97257+8.75898+9.61982+8.11173+10.4173+8.11173C11.2148+8.11173+11.8621+8.75898+11.8621+9.55648C11.8621+10.354+11.2148+11.0012+10.4173+11.0012ZM14.27+7.14857C13.4725+7.14857+12.8252+6.50132+12.8252+5.70382C12.8252+4.90632+13.4725+4.25907+14.27+4.25907C15.0675+4.25907+15.7147+4.90632+15.7147+5.70382C15.7147+6.50132+15.0675+7.14857+14.27+7.14857Z" opacity="1" fill="currentColor"/>
                                    <path stroke="currentColor" stroke-width="2" d="M7.44499+15.2526C6.69275+16.0049+3.19357+16.7802+3.19357+16.7802C3.19357+16.7802+3.96892+13.281+4.72115+12.5288" fill="none" stroke-linecap="square" opacity="1" stroke-linejoin="miter"/>
                                    <path d="M4.51792+11.9644L1.5716+11.9644C1.06401+11.9644+0.610358+11.662+0.416762+11.1929C0.222202+10.7238+0.329114+10.1893+0.687412+9.83099L2.90173+7.52613C3.13385+7.28534+3.45459+7.14857+3.78881+7.14857L6.08307+7.14857L4.51792+11.9644ZM9.26826+19.6534C9.10549+19.6534+8.93982+19.6225+8.7809+19.5561C8.31184+19.3634+8.0094+18.9098+8.0094+18.4022L8.0094+15.2555L12.8252+13.8907L12.8252+16.3015C12.8252+16.6473+12.6798+16.9777+12.4236+17.2108L10.1101+19.3172C9.89432+19.5339+9.58707+19.6534+9.26826+19.6534Z" opacity="1" fill="currentColor"/>
                                </svg>
                            </div>
                            <h2 className="uppercase mt-6 text-orange-excendo font-medium mb-3">Starta bolag</h2>
                            <p className="font-light text-sm text-gray-500 mb-3">
                                Vi hjälper Dig starta företaget oavsett vilken företagsform Du väljer, enskild firma, handelsbolag, kommanditbolag eller aktiebolag.
                            </p>
                            <Link to="/starta-bolag" className="text-orange-excendo flex items-center hover:text-red-600">
                                Läs mer
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"  fill="currentColor">
                                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                </svg>
                            </Link>
                        </div>

                        
                        <div className="px-8 md:px-0">
                            <div className="bg-blue-100 rounded-full w-16 h-16 flex justify-center items-center text-blue-excendo shadow-2xl">
                                <svg className="h-8 w-8" viewBox="0 0 20 20" fill="currentColor" >
                                    <path d="M0.328759+19.6385L0.328759+14.7839L3.56513+14.7839L3.56513+19.6385L0.328759+19.6385ZM4.37423+19.6385L4.37423+13.1657L7.6106+13.1657L7.6106+19.6385L4.37423+19.6385ZM8.41969+19.6385L8.41969+10.7385L11.6561+10.7385L11.6561+19.6385L8.41969+19.6385ZM12.4652+19.6385L12.4652+12.3566L15.7015+12.3566L15.7015+19.6385L12.4652+19.6385ZM16.5106+19.6385L16.5106+9.12027L19.747+9.12027L19.747+19.6385L16.5106+19.6385Z" opacity="1" fill="currentColor"/>
                                    <path stroke="currentColor" stroke-width="1.6" d="M1.94695+9.03936L5.99241+6.69299L10.0379+3.45662L14.0833+5.8839L18.1288+1.83843" fill="none" stroke-linecap="round" opacity="1" stroke-linejoin="round"/>
                                    <path d="M1.94695+7.50209C1.05325+7.50209+0.328759+8.22657+0.328759+9.12027C0.328759+10.014+1.05325+10.7385+1.94695+10.7385C2.84065+10.7385+3.56513+10.014+3.56513+9.12027C3.56513+8.22657+2.84065+7.50209+1.94695+7.50209ZM5.99241+5.07481C5.09871+5.07481+4.37423+5.79929+4.37423+6.69299C4.37423+7.58669+5.09871+8.31118+5.99241+8.31118C6.88611+8.31118+7.6106+7.58669+7.6106+6.69299C7.6106+5.79929+6.88611+5.07481+5.99241+5.07481ZM10.0379+1.83843C9.14418+1.83843+8.41969+2.56292+8.41969+3.45662C8.41969+4.35032+9.14418+5.07481+10.0379+5.07481C10.9316+5.07481+11.6561+4.35032+11.6561+3.45662C11.6561+2.56292+10.9316+1.83843+10.0379+1.83843ZM14.0833+4.26571C13.1896+4.26571+12.4652+4.9902+12.4652+5.8839C12.4652+6.7776+13.1896+7.50209+14.0833+7.50209C14.977+7.50209+15.7015+6.7776+15.7015+5.8839C15.7015+4.9902+14.977+4.26571+14.0833+4.26571ZM18.1288+0.220246C17.2351+0.220246+16.5106+0.944732+16.5106+1.83843C16.5106+2.73213+17.2351+3.45662+18.1288+3.45662C19.0225+3.45662+19.747+2.73213+19.747+1.83843C19.747+0.944733+19.0225+0.220246+18.1288+0.220246Z" opacity="1" fill="currentColor"/>
                                </svg>
                            </div>
                            <h2 className="uppercase mt-6 text-blue-excendo font-medium mb-3">Controllerfunktion</h2>
                            <p className="font-light text-sm text-gray-500 mb-3">
                            Vi arbetar med företag som kanske redan har en ekonomiavdelning men som också har en styrelse och vd som kräver mer än en avstämd resultat- och balansrapport vid månadsslutet.
                            </p>
                            <Link to="/controllerfunktion" className="text-blue-excendo flex items-center hover:text-blue-600">
                                Läs mer
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"  fill="currentColor">
                                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                </svg>
                            </Link>
                        </div>

                        
                        <div className="px-8 md:px-0">
                            <div className="bg-red-100 rounded-full w-16 h-16 flex justify-center items-center text-orange-excendo shadow-2xl">
                                <svg className="h-8 w-8" viewBox="0 0 20 20" fill="currentColor" >
                                    <path stroke="currentColor" stroke-width="2" d="M2.21613+2.36034L2.21613+19.3603" fill="none" stroke-linecap="butt" opacity="1" stroke-linejoin="miter"/>
                                    <path d="M19.2161+14.3603L11.5981+14.3603L10.5981+12.3603L1.21613+12.3603L1.21613+1.36034L11.8341+1.36034L12.8341+3.36034L19.2161+3.36034L19.2161+14.3603Z" opacity="1" fill="currentColor"/>
                                </svg>
                            </div>
                            <h2 className="uppercase mt-6 text-orange-excendo font-medium mb-3">Delgivningsmottagare</h2>
                            <p className="font-light text-sm text-gray-500 mb-3">
                                Excendo tillhandahåller en särskild delgivningsmottagare/delgivningsbar person samt utför administrativa tjänster åt bolag som saknar behörig ställföreträdare bosatt i Sverige.
                            </p>
                            <Link to="/delgivningsmottagare" className="text-orange-excendo flex items-center hover:text-red-600">
                                Läs mer
                                <svg className="h-5 w-5" viewBox="0 0 20 20"  fill="currentColor">
                                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                </svg>
                            </Link>
                        </div>

                        
                        
                    </div> 
                </div> 
                {/*-------------- other services - END ---------------*/}
            </section>

            <ContactSection />
            <Footer />
        </Layout>
    );
}

export default Radgivning;